.photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.photo {
  max-width: 1200px;
  height: auto;
  border-radius: 0.5rem;
  margin: 1rem 0 0 0;
}

.photo h2 {
  font-size: 1.5rem;
  margin: 1rem 0 2rem 0;
}
