/* Navbar styles */

.navbar {
  position: fixed;
  display: flex;
  width: 100%;
  top: 0;
  align-items: center;
  height: 6rem;
  background-color: #333;
  color: #fff;
}

.navbar-nav {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.navbar a {
  color: #fff;
  text-decoration: none;
}

.navbar a:hover {
  color: #ccc;
}

.navbar ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar li {
  display: inline;
  margin-right: 1rem;
}

.navbar h2 {
  margin-right: 1rem;
}
