/* LandingPage.css */

.landing-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.hero-section {
  flex: 1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-content {
  text-align: center;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem 3rem;
  border-radius: 0.5rem;
}

.hero-content h1 {
  font-size: 3rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.contact-info p,
.description p {
  margin-bottom: 0.5rem;
  max-width: 1200px;
}
